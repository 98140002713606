import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M3471 6994 c-328 -37 -576 -139 -737 -302 -162 -163 -238 -404 -215
-677 12 -135 25 -184 76 -290 107 -223 344 -381 655 -436 129 -23 273 -28 742
-29 595 0 675 -8 735 -72 64 -68 58 -220 -12 -275 -79 -63 -60 -62 -932 -62
l-791 -1 -39 -48 c-21 -27 -49 -64 -63 -82 -14 -18 -52 -67 -85 -110 -33 -42
-109 -141 -170 -219 -60 -79 -134 -173 -162 -210 -29 -36 -51 -70 -50 -76 3
-7 371 -11 1073 -13 927 -2 1086 -1 1199 13 488 60 784 247 896 565 44 128 51
165 56 335 4 152 3 179 -17 255 -54 212 -170 354 -367 454 -90 45 -130 59
-263 90 -156 37 -322 46 -875 46 -534 0 -594 4 -658 46 -47 31 -67 76 -67 151
0 70 22 119 67 149 59 40 146 44 853 44 l669 0 38 46 c21 25 53 63 71 84 17
20 32 40 32 44 0 5 103 142 224 296 29 36 58 75 66 86 8 12 42 57 74 100 32
43 59 85 60 93 1 14 -96 16 -984 17 -756 1 -1012 -2 -1099 -12z"
          />
          <path
            d="M5957 6998 c-16 -12 -125 -159 -219 -293 -23 -33 -44 -62 -48 -65 -4
-3 -22 -28 -41 -55 -19 -28 -56 -80 -82 -115 -106 -143 -147 -203 -147 -216 0
-12 152 -14 946 -14 689 0 958 -3 992 -12 104 -26 143 -67 150 -156 6 -87 -21
-135 -96 -170 l-57 -27 -867 -3 c-690 -2 -868 -5 -868 -15 0 -7 23 -35 51 -62
211 -207 314 -529 280 -875 -32 -331 -144 -584 -334 -754 -44 -40 -58 -59 -51
-66 8 -8 303 -10 990 -7 825 4 992 7 1064 20 236 42 367 88 500 176 206 135
310 352 310 649 0 136 -19 229 -68 332 -46 99 -175 229 -274 278 -37 18 -70
37 -72 41 -3 5 18 21 47 36 87 47 188 156 236 256 58 121 74 205 67 354 -6
139 -22 208 -70 302 -134 264 -404 410 -856 463 -132 16 -1462 13 -1483 -2z
m1402 -1772 c104 -23 151 -71 159 -161 6 -69 -17 -133 -58 -163 -66 -49 -109
-52 -641 -50 l-494 3 -3 180 c-1 98 0 185 2 192 8 19 945 18 1035 -1z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
